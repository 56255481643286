<template>
  <Navbar>
    <template v-slot:navbarTitle>仕入先管理</template>
  </Navbar>

  <main id="main" class="container-fluid pt-3">
    <div class="clearfix mb-3">
      <div class="float-end d-print-none">
        <button
          type="button"
          v-on:click.prevent="removeData()"
          class="btn btn-outline-danger me-1"
        >
          <span class="d-block d-sm-none"><i class="bi bi-x"></i></span>
          <span class="d-none d-sm-block"
            ><i class="bi bi-x me-1"></i>削除する</span
          >
        </button>
        <button
          type="button"
          v-on:click.prevent="showSupplierForm()"
          class="btn btn-outline-success me-1"
        >
          <span class="d-block d-sm-none"><i class="bi bi-pencil"></i></span>
          <span class="d-none d-sm-block"
            ><i class="bi bi-pencil me-1"></i>編集する</span
          >
        </button>
        <router-link
          :to="{
            name: 'SupplierList',
          }"
          class="btn btn-outline-dark"
        >
          <span class="d-block d-sm-none"
            ><i class="bi bi-arrow-left"></i
          ></span>
          <span class="d-none d-sm-block">一覧に戻る</span>
        </router-link>
      </div>
      <h5><i class="bi bi-building me-1"></i>仕入先詳細</h5>
    </div>

    <div v-if="!is_loading">
      <h5>
        仕入先データ
        <a
          class="fs-6"
          data-bs-toggle="collapse"
          href="#resultData"
          role="button"
          aria-expanded="true"
          aria-controls="resultData"
          ><i class="bi bi-arrows-collapse"></i>
        </a>
      </h5>

      <div class="collapse show" id="resultData">
        <div class="row row-cols-1 row-cols-md-2 g-1 g-lg-2">
          <div class="col">
            <table class="table table-bordered table-row bg-white mb-3">
              <tbody>
                <tr>
                  <th class="col-sm-4 bg-light">仕入先コード</th>
                  <td class="col-sm-8">{{ supplier.code }}</td>
                </tr>
                <tr>
                  <th class="bg-light">仕入先名</th>
                  <td>{{ supplier.name }}</td>
                </tr>
                <tr>
                  <th class="bg-light">仕入先名（ふりがな）</th>
                  <td>{{ supplier.kana }}</td>
                </tr>
                <tr>
                  <th class="bg-light">郵便番号</th>
                  <td>{{ supplier.postal_code }}</td>
                </tr>
                <tr>
                  <th class="bg-light">住所</th>
                  <td>{{ supplier.address }}</td>
                </tr>
                <tr>
                  <th class="bg-light">電話番号</th>
                  <td>{{ supplier.phone_number }}</td>
                </tr>
                <tr>
                  <th class="bg-light">メールアドレス</th>
                  <td>{{ supplier.email }}</td>
                </tr>
                <tr>
                  <th class="bg-light">メモ</th>
                  <td>{{ supplier.memo }}</td>
                </tr>
                <tr>
                  <th class="bg-light">登録日時</th>
                  <td>{{ supplier.created_at }}</td>
                </tr>
                <tr>
                  <th class="bg-light">更新日時</th>
                  <td>{{ supplier.updated_at }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>

    <DeleteDialog ref="confirmDataDelete" @confirm-true="confirmDataDelete" />
    <supplier-form ref="supplierForm">
      <template v-slot:modalTitle>仕入先データ編集</template>
    </supplier-form>
  </main>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import Navbar from '@/components/Navbar.vue'
import DeleteDialog from '@/components/AppDeleteDialog.vue'
import SupplierForm from '@/components/manage/SupplierForm.vue'

export default {
  name: 'SupplierDetail',
  components: {
    Navbar,
    DeleteDialog,
    SupplierForm,
  },

  props: {
    id: {
      type: Number,
      required: true,
    },
  },

  data() {
    return {
      is_loading: true,
    }
  },
  computed: {
    ...mapGetters({
      supplier: 'supplier/detail',
    }),
  },

  async created() {
    try {
      await this.getData(this.id)
    } catch (error) {
      if (error.status) {
        window.setTimeout(() => {
          this.$router.push({
            name: 'SupplierList',
          })
        }, this.$store.getters['alert/timeout'])
      }
    } finally {
      this.is_loading = false
    }
  },

  methods: {
    ...mapActions({
      getData: 'supplier/fetchData',
      deleteData: 'supplier/deleteData',
    }),
    showSupplierForm() {
      this.$refs.supplierForm.show(this.supplier)
    },
    removeData() {
      this.$refs.confirmDataDelete.showDialog(
        '仕入先データ',
        this.supplier.name
      )
    },
    async confirmDataDelete() {
      try {
        await this.deleteData(this.id)
        await this.$router.push({
          name: 'SupplierList',
        })
      } catch (e) {
        if (e.response.status == 400) {
          this.$store.dispatch('alert/setErrorMessage', {
            message: e.response.data,
          })
        }
      }
    },
  },
}
</script>
