import api from '@/api'

// State（状態）
// https://next.vuex.vuejs.org/ja/guide/state.html
// 状態を監視するデータ
const state = () => ({
  data: {},
  detail: {},
  list: [],
})

// Getters（算出プロパティ）
// https://next.vuex.vuejs.org/ja/guide/getters.html
// コンポーネントから状態を取得するプロパティまたは関数
const getters = {
  results: (state) => state.data.results,
  count: (state) => state.data.count,
  prev: (state) => state.data.previous,
  next: (state) => state.data.next,
  current_page: (state) => state.data.current_page,
  total_pages: (state) => state.data.total_pages,
  detail: (state) => state.detail,
  list: (state) => state.list,
}

// Mutations（状態変更）
// https://next.vuex.vuejs.org/ja/guide/mutations.html
// 状態を変更するメソッド
const mutations = {
  setData: (state, data) => (state.data = data),
  clearData: (state) => (state.data = []),
  setDetail: (state, detail) => (state.detail = detail),
  clearDetail: (state) => (state.detail = {}),
  setList: (state, list) => (state.list = list),
  clearList: (state) => (state.list = []),
}

// Actions（処理）
// https://next.vuex.vuejs.org/ja/guide/actions.html
// コンポーネントからミューテーションを実行する関数
const actions = {
  clearAll({ commit }) {
    return new Promise((resolve) => {
      commit('clearData')
      commit('clearDetail')
      commit('clearList')
      resolve()
    })
  },
  async fetchAll({ commit }, queryParams) {
    const response = await api.get('manage/stocks/', {
      params: queryParams,
    })
    commit('setData', response.data)
  },
  async fetchData({ state, commit }, id) {
    if (Object.keys(state.detail).length > 0 && state.detail.id == id) {
      return
    }

    const response = await api.get('manage/stocks/' + id + '/')
    commit('setDetail', response.data)
  },
  async refetchData({ commit }, id) {
    const response = await api.get('manage/stocks/' + id + '/')
    commit('setDetail', response.data)
  },
  async fetchList({ commit }, query_params) {
    const response = await api.get('manage/stocks/list/', {
      params: query_params,
    })
    commit('setList', response.data)
  },
  /**
   * データを追加する
   *
   * @param {object}
   * @return {object}
   */
  async postData({ state }, formData) {
    try {
      const response = await api.post('manage/stocks/', formData)
      state.data.results.push(response.data)
    } catch (error) {
      throw error
    }
  },
  /**
   * データを更新する
   *
   * @param {object}
   * @return {object}
   */
  async patchData({ state, commit }, formData) {
    try {
      const response = await api.patch(
        'manage/stocks/' + formData.id + '/',
        formData
      )
      commit('setDetail', Object.assign(state.detail, response.data))
    } catch (error) {
      throw error
    }
  },
  /**
   * データを作成または更新する
   *
   * @param {object}
   * @return {object}
   */
  async postOrPatch({ dispatch }, formData) {
    // データをフラットにする
    formData = flattenObject(formData)
    // 必要なデータのみにする
    const requiredKeys = ['id', 'threshold', 'item_id']
    const submitData = Object.fromEntries(
      Object.entries(formData).filter(([key, value]) =>
        requiredKeys.includes(key)
      )
    )

    try {
      if ('id' in submitData && submitData['id']) {
        await dispatch('patchData', submitData)
      } else {
        await dispatch('postData', submitData)
      }
    } catch (error) {
      throw error
    }
  },
  /**
   * データを削除する
   *
   * @param {number}
   * @return {void}
   */
  async deleteData({ dispatch }, id) {
    await api.delete('manage/stocks/' + id + '/')
    dispatch('clearAll')
  },
  /**
   * 入庫
   *
   * @param {object}
   * @return {object}
   */
  async incoming({ state, dispatch }, formData) {
    // 必要なデータのみにする
    const requiredKeys = ['quantity', 'reason', 'stock_location_id']
    const submitData = Object.fromEntries(
      Object.entries(formData).filter(([key, value]) =>
        requiredKeys.includes(key)
      )
    )

    try {
      const detail = state.detail
      await api.post(
        'manage/stocks/' + detail.id + '/incoming/',
        submitData
      )
      await dispatch('refetchData', detail.id)
    } catch (error) {
      throw error
    }
  },
  /**
   * 出庫
   *
   * @param {object}
   * @return {object}
   */
  async outgoing({ state, dispatch }, formData) {
    const requiredKeys = ['quantity', 'reason', 'stock_location_id']
    const submitData = Object.fromEntries(
      Object.entries(formData).filter(([key, value]) =>
        requiredKeys.includes(key)
      )
    )

    try {
      const detail = state.detail
      await api.post(
        'manage/stocks/' + detail.id + '/outgoing/',
        submitData
      )
      await dispatch('refetchData', detail.id)
    } catch (error) {
      throw error
    }
  },
  /**
   * CSVファイルをインポートする
   *
   * @param {object}
   * @return {object}
   */
  async importData({}, formData) {
    try {
      await api.post('manage/stocks/import/', formData)
    } catch (error) {
      throw error
    }
  },
}

/**
 * Flattens a nested object structure into a single-level object with concatenated keys.
 *
 * @param {Object} obj - The object to be flattened.
 * @param {string} [parent=''] - The parent key used for key concatenation (used for recursion).
 * @returns {Object} - The flattened object.
 *
 * @example
 * const data = { "item": { "id": 1, "code": "test" } };
 * const flatData = flattenObject(data);
 * console.log(flatData);  // Outputs: { "item_id": 1, "item_code": "test" }
 */
const flattenObject = (obj, parent = '') => {
  let result = {}

  for (let key in obj) {
    let propName = parent ? parent + '_' + key : key
    if (typeof obj[key] === 'object') {
      Object.assign(result, flattenObject(obj[key], propName))
    } else {
      result[propName] = obj[key]
    }
  }
  return result
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}
