<template>
  <Navbar>
    <template v-slot:navbarTitle>品目管理</template>
  </Navbar>

  <main id="main" class="container-fluid pt-3">
    <div class="d-flex gap-2 mb-3">
      <h5 class="m-0 p-0"><i class="bi bi-box me-1"></i>品目一覧</h5>
      <div class="ms-auto">
        <router-link
          :to="{
            name: 'ItemImport',
          }"
          class="btn btn-dark me-2"
          ><span class="d-block d-sm-none"
            ><i class="bi bi-cloud-upload"></i
          ></span>
          <span class="d-none d-sm-block"
            ><i class="bi bi-cloud-upload me-1"></i>CSVから一括で登録する</span
          ></router-link
        >
        <button
          type="button"
          v-on:click.prevent="showItemForm()"
          class="btn btn-primary"
        >
          <span class="d-block d-sm-none"><i class="bi bi-plus-lg"></i></span>
          <span class="d-none d-sm-block"
            ><i class="bi bi-plus-lg me-1"></i>品目を登録する</span
          >
        </button>
      </div>
    </div>

    <div class="d-flex justify-content-start gap-2 mb-2">
      <search-form
        :query="query"
        query-key="code"
        placeholder="品目コードで検索"
        @update-search-query="onUpdateSearchQuery"
        @open-search-modal="onOpenSearchModal"
      ></search-form>
      <Pagination :prev="prev" :next="next" @move-page="movePage" />
    </div>

    <!-- items -->
    <div v-if="items && items.length > 0">
      <data-table :headers="dataHeaders" :items="items">
        <template #code="{ item }">
          <router-link
            :to="{
              name: 'ItemDetail',
              params: { id: item.id },
            }"
            >{{ item.code }}</router-link
          >
        </template>
        <template #classifications="{ item }">
          {{
            item.classifications
              ? item.classifications.map((obj) => obj.name).join(', ')
              : ''
          }}
        </template>
      </data-table>
    </div>
    <p v-else class="mt-3">該当するデータはありません。</p>

    <!-- Modal -->
    <search-modal
      :query="query"
      :items="searchItems"
      @update-search-query="onUpdateSearchQuery"
      ref="searchModal"
    ></search-modal>

    <item-form ref="itemForm" @after-submit="afterSubmit">
      <template v-slot:modalTitle>品目登録</template>
    </item-form>
  </main>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import Navbar from '@/components/Navbar.vue'
import Pagination from '@/components/AppNoCountPagination.vue'
import DataTable from '@/components/common/DataTable.vue'
import SearchForm from '@/components/common/SearchForm.vue'
import SearchModal from '@/components/common/SearchModal.vue'
import ItemForm from '@/components/manage/ItemForm.vue'

export default {
  name: 'ItemList',
  components: {
    Navbar,
    Pagination,
    DataTable,
    SearchForm,
    SearchModal,
    ItemForm,
  },

  data() {
    return {
      query: {},
      dataHeaders: [
      {
          key: 'code',
          label: '品目コード',
          class: 'col-2',
          format: 'code',
        },
        {
          key: 'item_model',
          label: '型式',
          class: 'col-2',
          format: 'code',
        },
        {
          key: 'name',
          label: '品目名',
          class: 'col-4',
          format: 'string',
        },
        {
          key: 'classifications',
          label: '品目分類',
          class: 'col-2',
          format: 'string',
        },
        {
          key: 'created_at',
          label: '登録日時',
          class: 'col-1',
          format: 'datetime',
        },
        {
          key: 'updated_at',
          label: '更新日時',
          class: 'col-1',
          format: 'datetime',
        },
      ],
    }
  },
  computed: {
    ...mapGetters({
      items: 'item/results',
      prev: 'item/prev',
      next: 'item/next',
    }),
    searchItems() {
      return [
      {
          id: 'code',
          label: '品目コード（前方一致）',
          type: 'text',
        },
        {
          id: 'item_model',
          label: '型式（前方一致）',
          type: 'text',
        },
        {
          id: 'name',
          label: '品目名（部分一致）',
          type: 'text',
        },
      ]
    },
  },

  async created() {
    await this.getData()
  },

  methods: {
    ...mapActions({
      getData: 'item/fetchAll',
    }),
    movePage(page) {
      this.getData(Object.assign(this.query, { page: page }))
    },
    getQueryResult() {
      this.getData(this.query)
    },
    onOpenSearchModal() {
      this.$refs.searchModal.show()
    },
    onUpdateSearchQuery(query = {}) {
      this.query = query
      this.getQueryResult()
    },
    showItemForm() {
      this.$refs.itemForm.show()
    },
    afterSubmit() {
      this.getQueryResult()
    },
  },
}
</script>
