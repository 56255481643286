import ItemHome from '@/views/item/Home.vue'
import ItemList from '@/views/item/ItemList.vue'
import ItemDetail from '@/views/item/ItemDetail.vue'
import ItemClassificationList from '@/views/item/ItemClassificationList.vue'
import ItemImport from '@/views/item/ItemImport.vue'

export default {
  routes: [
    {
      path: '/item',
      component: ItemHome,
      name: 'ItemHome',
      meta: { requiresAuth: true },
      children: [
        {
          path: 'items',
          component: ItemList,
          name: 'ItemList',
        },
        {
          path: 'items/import',
          component: ItemImport,
          name: 'ItemImport',
        },
        {
          path: 'items/:id([0-9]+)',
          component: ItemDetail,
          name: 'ItemDetail',
          props: (route) => ({ id: Number(route.params.id) }),
        },
        {
          path: 'item-classifications',
          component: ItemClassificationList,
          name: 'ItemClassificationList',
        },
      ],
    },
  ],
}
