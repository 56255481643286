<template>
  <Navbar>
    <template v-slot:navbarTitle>顧客管理</template>
  </Navbar>

  <main id="main" class="container-fluid pt-3">
    <div class="d-flex gap-2 mb-3">
      <h5 class="m-0 p-0"><i class="bi bi-building me-1"></i>顧客一覧</h5>
      <div class="ms-auto">
        <router-link
          :to="{
            name: 'ClientImport',
          }"
          class="btn btn-dark me-2"
          ><span class="d-block d-sm-none"
            ><i class="bi bi-cloud-upload"></i
          ></span>
          <span class="d-none d-sm-block"
            ><i class="bi bi-cloud-upload me-1"></i>CSVから一括で登録する</span
          ></router-link
        >
        <button
          type="button"
          v-on:click.prevent="showClientForm()"
          class="btn btn-primary"
        >
          <span class="d-block d-sm-none"><i class="bi bi-plus-lg"></i></span>
          <span class="d-none d-sm-block"
            ><i class="bi bi-plus-lg me-1"></i>顧客情報を登録する</span
          >
        </button>
      </div>
    </div>

    <div class="d-flex justify-content-start gap-2 mb-2">
      <search-form
        :query="query"
        query-key="code"
        placeholder="顧客コードで検索"
        @update-search-query="onUpdateSearchQuery"
        @open-search-modal="onOpenSearchModal"
      ></search-form>
      <Pagination :prev="prev" :next="next" @move-page="movePage" />
    </div>

    <!-- clients -->
    <div v-if="clients && clients.length > 0">
      <data-table :headers="dataHeaders" :items="clients">
        <template #code="{ item }">
          <router-link
            :to="{
              name: 'ClientDetail',
              params: { id: item.id },
            }"
            >{{ item.code }}</router-link
          >
        </template>
      </data-table>
    </div>
    <p v-else class="mt-3">該当するデータはありません。</p>

    <!-- Modal -->
    <search-modal
      :query="query"
      :items="searchItems"
      @update-search-query="onUpdateSearchQuery"
      ref="searchModal"
    ></search-modal>

    <client-form ref="clientForm" @after-submit="afterSubmit">
      <template v-slot:modalTitle>顧客情報登録</template>
    </client-form>
  </main>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import Navbar from '@/components/Navbar.vue'
import Pagination from '@/components/AppNoCountPagination.vue'
import DataTable from '@/components/common/DataTable.vue'
import SearchForm from '@/components/common/SearchForm.vue'
import SearchModal from '@/components/common/SearchModal.vue'
import ClientForm from '@/components/manage/ClientForm.vue'

export default {
  name: 'ClientList',
  components: {
    Navbar,
    Pagination,
    DataTable,
    SearchForm,
    SearchModal,
    ClientForm,
  },

  data() {
    return {
      query: {},
      dataHeaders: [
        {
          key: 'code',
          label: '顧客コード',
          class: 'col-2',
          format: 'code',
        },
        {
          key: 'name',
          label: '顧客名',
          class: 'col-3',
          format: 'string',
        },
        {
          key: 'kana',
          label: '顧客名（ふりがな）',
          class: 'col-3',
          format: 'string',
        },
        {
          key: 'created_at',
          label: '登録日時',
          class: 'col-2',
          format: 'datetime',
        },
        {
          key: 'updated_at',
          label: '更新日時',
          class: 'col-2',
          format: 'datetime',
        },
      ],
    }
  },
  computed: {
    ...mapGetters({
      clients: 'client/results',
      prev: 'client/prev',
      next: 'client/next',
    }),
    searchItems() {
      return [
        {
          id: 'code',
          label: '顧客コード（前方一致）',
          type: 'text',
        },
        {
          id: 'name',
          label: '顧客名（部分一致）',
          type: 'text',
        },
        {
          id: 'kana',
          label: 'ふりがな（部分一致）',
          type: 'text',
        },
        {
          id: 'postal_code',
          label: '郵便番号（前方一致）',
          type: 'text',
        },
        {
          id: 'phone_number',
          label: '電話番号（前方一致）',
          type: 'text',
        },
      ]
    },
  },

  async created() {
    await this.getData()
  },

  methods: {
    ...mapActions({
      getData: 'client/fetchAll',
    }),
    movePage(page) {
      this.getData(Object.assign(this.query, { page: page }))
    },
    getQueryResult() {
      this.getData(this.query)
    },
    onOpenSearchModal() {
      this.$refs.searchModal.show()
    },
    onUpdateSearchQuery(query = {}) {
      this.query = query
      this.getQueryResult()
    },
    showClientForm() {
      this.$refs.clientForm.show()
    },
    afterSubmit() {
      this.getQueryResult()
    },
  },
}
</script>
