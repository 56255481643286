<template>
  <!-- Modal -->
  <div
    class="modal fade"
    :id="modalId"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    tabindex="-1"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">
            <slot name="modalTitle">単価</slot>
          </h5>
        </div>
        <div class="modal-body">
          <div class="row g-3">
            <div class="col-12">
              <label :for="modalId + '_form_item'" class="form-label"
                >品目<span class="badge text-bg-danger ms-2 px-1"
                  >必須</span
                ></label
              >
              <FormSearchSelect
                v-model="formData.item"
                :options="itemList"
                :error="formErrors.item_id"
                :id="modalId + '_form_item'"
                placeholder="品目コード、品目名で検索"
                @getOptions="getItemList"
              />
            </div>
            <div class="col-12">
              <label :for="modalId + '_form_client'" class="form-label"
                >顧客</label
              >
              <FormSearchSelect
                v-model="formData.client"
                :options="clientList"
                :error="formErrors.client_id"
                :id="modalId + '_form_client'"
                placeholder="顧客コード・顧客名で検索"
                @getOptions="getClientList"
              />
            </div>
            <div class="col-12">
              <label :for="modalId + '_form_unit_price'" class="form-label"
                >単価<span class="badge text-bg-danger ms-2 px-1"
                  >必須</span
                ></label
              >
              <FormNumericInput
                v-model="formData.unit_price"
                :error="formErrors.unit_price"
                :id="modalId + '_form_unit_price'"
              />
            </div>
            <div class="col-12">
              <label :for="modalId + '_form_effective_date'" class="form-label"
                >有効日</label
              >
              <input
                v-model="formData.effective_date"
                type="date"
                class="form-control"
                :class="{
                  'is-invalid': formErrors?.effective_date,
                }"
                :id="modalId + '_form_effective_date'"
              />
              <p class="form-text text-muted small">
                単価を適用する日付を指定する場合は入力してください。
              </p>
              <div v-if="formErrors?.effective_date" class="invalid-feedback">
                {{ formErrors.effective_date }}
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-secondary"
            data-bs-dismiss="modal"
          >
            閉じる
          </button>
          <button
            type="button"
            class="btn btn-dark"
            v-on:click.prevent="formSubmit()"
          >
            <slot name="submitButton">保存</slot>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Modal } from 'bootstrap/dist/js/bootstrap.esm.min.js'
import { mapGetters, mapActions } from 'vuex'
import { randomString } from '@/utils/stringUtils.js'
import FormSearchSelect from '@/components/common/FormSearchSelect.vue'
import FormNumericInput from '@/components/common/FormNumericInput.vue'

export default {
  name: 'ItemPriceForm',
  components: {
    FormSearchSelect,
    FormNumericInput,
  },

  emits: ['hiddenModal', 'afterSubmit'],

  data() {
    return {
      modal: null,
      formData: {},
      formErrors: {},
    }
  },
  computed: {
    ...mapGetters({
      itemList: 'item/list',
      clientList: 'client/list',
    }),
    modalId: function () {
      return 'form-modal-' + this.randomString()
    },
  },

  mounted() {
    this.modal = new Modal(document.getElementById(this.modalId))
    document
      .getElementById(this.modalId)
      .addEventListener('hidden.bs.modal', () => {
        this.formErrors = {}
        this.$emit('hiddenModal')
      })
  },
  beforeUnmount() {
    this.modal.hide()
  },

  methods: {
    randomString,
    ...mapActions({
      getItemList: 'item/fetchList',
      getClientList: 'client/fetchList',
      postData: 'item_price/postData',
    }),
    show(formData = {}) {
      this.formData = Object.assign({}, formData)
      this.modal.show()
    },
    async formSubmit() {
      try {
        this.formErrors = {}
        await this.postData(this.formData)
        this.$store.dispatch('alert/setSuccessMessage', {
          message: '保存しました。',
        })
        await this.$emit('afterSubmit', this.formData)
        await this.modal.hide()
      } catch (e) {
        if (e.response.status == 400) {
          Object.keys(e.response.data).forEach((key) => {
            this.setErrorMessage(key, e.response.data[key])
          })
        }
      }
    },
    setErrorMessage(key, errorData) {
      if (Array.isArray(errorData)) {
        this.formErrors[key] = errorData.join('\n')
      } else {
        this.formErrors[key] = errorData
      }
    },
  },
}
</script>
