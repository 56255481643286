<template>
  <div
    class="modal fade"
    id="imageModal"
    tabindex="-1"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-lg modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-body">
          <img v-if="image" :src="image" class="img-fluid">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Modal } from 'bootstrap/dist/js/bootstrap.esm.min.js'

export default {
  name: 'ImageModal',
  components: {},

  emits: ['hiddenModal'],

  data() {
    return {
      modal: null,
      image: '',
    }
  },

  mounted() {
    this.modal = new Modal(document.getElementById('imageModal'))
    document
      .getElementById('imageModal')
      .addEventListener('hidden.bs.modal', () => {
        this.image = ''
        this.$emit('hiddenModal')
      })
  },
  beforeUnmount() {
    this.modal.hide()
  },

  methods: {
    showModal(image) {
      if (image) {
        this.image = image
      }
      this.modal.show()
    },
  },
}
</script>
