<template>
  <CodeReader
    v-if="showCodeCamera"
    ref="codeReader"
    @decode="handleQRCodeData"
    @cancel="cancelCodeCamera"
    class="animate__animated animate__fadeIn"
  />

  <div v-else>
    <Navbar>
      <template v-slot:navbarTitle>
        <h1 class="h5 m-0">出庫</h1>
      </template>
      <template v-slot:navbarButton>
        <router-link
          :to="{
            name: 'OperateInventory',
          }"
          class="btn btn-dark"
          >戻る</router-link
        >
      </template>
    </Navbar>

    <main id="main" class="bg-light">
      <div class="container py-3 py-lg-4">
        <div class="row row-cols-1 gy-2 gy-lg-3">
          <div class="col">
            <div class="card">
              <div class="card-body p-2 p-lg-3">
                <div class="input-group">
                  <button
                    type="button"
                    class="btn btn-outline-secondary"
                    v-on:click.prevent="startCodeCamera"
                  >
                    <i class="bi bi-qr-code"></i>
                  </button>
                  <FormSearchInput
                    ref="dropdown"
                    v-model="query.code"
                    :options="stockList"
                    :placeholder="'品目コード'"
                    @selectOption="selectOption"
                    @fetchOptions="getStockList"
                  >
                    <template #default="{ option }">
                      <span>{{ option.code }} - {{ option.name }}</span>
                    </template>
                  </FormSearchInput>
                  <button
                    v-on:click.prevent="resetSearch()"
                    class="btn bg-white text-secondary border border-start-0"
                    type="button"
                  >
                    <i class="bi bi-x"></i>
                  </button>
                  <button
                    type="submit"
                    class="btn btn-secondary"
                    v-on:click.prevent="submitSearch()"
                  >
                    <span class="d-block d-sm-none"
                      ><i class="bi bi-search d-block d-sm-none"></i
                    ></span>
                    <span class="d-none d-sm-block">検索</span>
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div class="col">
            <div
              v-if="stock && Object.keys(stock).length > 0"
              class="row row-cols-1 row-cols-lg-2 g-2"
            >
              <div class="col-lg-8">
                <div
                  v-if="stock.images && stock.images.length > 0"
                  class="mb-2"
                >
                  <ImageViewer
                    :file="{
                      file_path: stock.images[0].file_path,
                      file_type: stock.images[0].file_type,
                    }"
                    :show_caption="false"
                    :show_lightbox="false"
                  />
                </div>
                <table class="table table-bordered table-row bg-white m-0 mb-1">
                  <tbody>
                    <tr>
                      <th class="col-5 bg-light">品目コード</th>
                      <td class="col-7">{{ stock.code }}</td>
                    </tr>
                    <tr>
                      <th class="bg-light">型式</th>
                      <td>{{ stock.item_model }}</td>
                    </tr>
                    <tr>
                      <th class="bg-light">品目名</th>
                      <td>{{ stock.name }}</td>
                    </tr>
                    <tr>
                      <th class="bg-light">在庫数量</th>
                      <td>{{ stock.quantity }}</td>
                    </tr>
                    <tr>
                      <th class="bg-light">在庫閾値</th>
                      <td>{{ stock.threshold }}</td>
                    </tr>
                  </tbody>
                </table>
                <div v-if="stock.locations && stock.locations.length > 0">
                  <table class="table table-bordered bg-white m-0">
                    <thead>
                      <tr>
                        <th scope="col" class="col-6">保管場所名</th>
                        <th scope="col" class="col-6">保管在庫数</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="location in stock.locations"
                        :key="location.id"
                      >
                        <td>{{ location.name }}</td>
                        <td class="text-end">{{ location.quantity }}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div class="col-lg-4">
                <div class="card">
                  <div class="card-body">
                    <div class="row gy-2">
                      <div v-if="!formData?.id" class="col-12">
                        <label for="form_stock_location" class="form-label"
                          >保管場所<span class="badge text-bg-danger ms-2 px-1"
                            >必須</span
                          ></label
                        >
                        <FormSearchSelect
                          v-model="formData.stock_location"
                          :options="locationList"
                          :error="formErrors.stock_location_id"
                          id="form_stock_location"
                          placeholder="保管場所名で検索"
                          @getOptions="getLocationList"
                        />
                      </div>
                      <div class="col-12">
                        <label for="form_quantity" class="form-label"
                          >数量</label
                        >
                        <FormNumericInput
                          v-model="formData.quantity"
                          :error="formErrors.quantity"
                          id="form_quantity"
                        />
                      </div>
                      <div class="col-12">
                        <label for="form_reason" class="form-label">メモ</label>
                        <input
                          v-model="formData.reason"
                          type="text"
                          class="form-control"
                          :class="{ 'is-invalid': formErrors?.reason }"
                          id="form_reason"
                        />
                        <div v-if="formErrors?.reason" class="invalid-feedback">
                          {{ formErrors.reason }}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="card-footer">
                    <button
                      type="button"
                      class="btn btn-primary w-100"
                      v-on:click.prevent="formSubmit()"
                    >
                      出庫
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div v-else class="alert alert-warning" role="alert">
              品目コードを入力してください
            </div>
          </div>
        </div>
      </div>
    </main>
  </div>
</template>

<script>
import store from '../store'
import CodeReader from '@/components/AppCodeReader.vue'
import ImageViewer from '@/components/AppImageViewer.vue'
import Navbar from '../components/InventoryNavbar.vue'
import FormSearchSelect from '@/components/common/FormSearchSelect.vue'
import FormNumericInput from '@/components/common/FormNumericInput.vue'
import FormSearchInput from '@/components/common/FormSearchInput.vue'

export default {
  name: 'OperateStockIncoming',
  components: {
    CodeReader,
    ImageViewer,
    Navbar,
    FormSearchSelect,
    FormNumericInput,
    FormSearchInput,
  },

  data() {
    return {
      query: {
        code: '',
      },
      showCodeCamera: false,
      formData: {},
      formErrors: {},
    }
  },
  computed: {
    stockList() {
      return store.getters['operate_stock/list']
    },
    locationList() {
      return store.getters['operate_stock_location/list']
    },
    stock() {
      return store.getters['operate_stock/detail']
    },
  },

  unmounted() {
    this.clearStock()
  },

  methods: {
    clearStock() {
      store.dispatch('operate_stock/clearAll')
      this.formData = {}
    },
    async getStock(query) {
      return await store.dispatch('operate_stock/searchData', query)
    },
    async getLocationList(query = {}) {
      return await store.dispatch('operate_stock_location/fetchList', query)
    },
    async getStockList(keyword) {
      await store.dispatch('operate_stock/fetchList', { q: keyword })
    },
    selectOption(option) {
      this.query.code = option.code
    },
    startCodeCamera() {
      this.showCodeCamera = true
    },
    cancelCodeCamera() {
      this.showCodeCamera = false
    },
    handleQRCodeData(result) {
      this.query.code = result
      this.showCodeCamera = false
    },
    async submitSearch() {
      if (!this.query.code) {
        this.$store.dispatch('alert/setErrorMessage', {
          message: '品目コードを入力してください',
        })
      } else {
        try {
          await this.getStock(this.query)
        } catch (error) {
          this.clearStock()
        }
      }
    },
    resetSearch() {
      this.query.code = ''
      this.clearStock()
    },
    async formSubmit() {
      this.formErrors = {}
      if (!this.formData.stock_location) {
        this.formErrors.stock_location_id = '保管場所を選択してください。'
      }
      if (!this.formData.quantity || this.formData.quantity.length === 0) {
        this.formErrors.quantity = '数量を入力してください。'
      }
      if (Object.keys(this.formErrors).length > 0) {
        return
      }

      try {
        await store.dispatch('operate_stock/outgoing', this.formData)
        this.formData = {}
        this.$store.dispatch('alert/setSuccessMessage', {
          message: '保存しました',
        })
      } catch (e) {
        if (e.response.status == 400) {
          Object.keys(e.response.data).forEach((key) => {
            this.setErrorMessage(key, e.response.data[key])
          })
        }
      }
    },
    setErrorMessage(key, errorData) {
      if (Array.isArray(errorData)) {
        this.formErrors[key] = errorData.join('\n')
      } else {
        this.formErrors[key] = errorData
      }
    },
  },
}
</script>
