import api from '@/api'

// State（状態）
// https://next.vuex.vuejs.org/ja/guide/state.html
// 状態を監視するデータ
const state = () => ({
  data: {},
})

// Getters（算出プロパティ）
// https://next.vuex.vuejs.org/ja/guide/getters.html
// コンポーネントから状態を取得するプロパティまたは関数
const getters = {
  results: (state) => state.data.results,
  count: (state) => state.data.count,
  prev: (state) => state.data.previous,
  next: (state) => state.data.next,
  current_page: (state) => state.data.current_page,
  total_pages: (state) => state.data.total_pages,
}

// Mutations（状態変更）
// https://next.vuex.vuejs.org/ja/guide/mutations.html
// 状態を変更するメソッド
const mutations = {
  setData: (state, data) => (state.data = data),
  clearData: (state) => (state.data = []),
}

// Actions（処理）
// https://next.vuex.vuejs.org/ja/guide/actions.html
// コンポーネントからミューテーションを実行する関数
const actions = {
  clearAll({ commit }) {
    return new Promise((resolve) => {
      commit('clearData')
      resolve()
    })
  },
  async fetchAll({ commit }, queryParams) {
    const response = await api.get('manage/item-prices/', {
      params: queryParams,
    })
    commit('setData', response.data)
  },
  /**
   * 最新の価格を取得する
   *
   * @param {object} queryParams
   */
  async fetchCurrentPrice({}, queryParams) {
    const item_id = queryParams['item_id']
    return await api.get(
      'manage/item-prices/' + item_id + '/current/',
      {
        params: queryParams,
      }
    )
  },
  /**
   * データを追加する
   *
   * @param {object}
   * @return {object}
   */
  async postData({ state }, formData) {
    if ('item' in formData && formData['item']) {
      formData['item_id'] = formData['item']['id']
    }
    if ('client' in formData && formData['client']) {
      formData['client_id'] = formData['client']['id']
    }
    // 必要なデータのみにする
    const requiredKeys = [
      'id',
      'item_id',
      'client_id',
      'unit_price',
      'effective_date',
    ]
    const submitData = Object.fromEntries(
      Object.entries(formData).filter(([key, value]) =>
        requiredKeys.includes(key)
      )
    )

    try {
      const response = await api.post('manage/item-prices/', submitData)
      state.data.results.push(response.data)
    } catch (error) {
      throw error
    }
  },
  /**
   * CSVファイルをインポートする
   *
   * @param {object}
   * @return {object}
   */
  async importData({}, formData) {
    try {
      await api.post('manage/item-prices/import/', formData)
    } catch (error) {
      throw error
    }
  },
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}
