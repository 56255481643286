import { createStore } from 'vuex'
import auth from './modules/auth'
import alert from './modules/alert'
import loading from './modules/loading'
import master from './modules/master'
import user from './modules/user'
import item from './item/item'
import item_classification from './item/item_classification'
import item_price from './item_price/item_price'
import client from './client/client'
import supplier from './supplier/supplier'
import export_data from './export_data/export_data'
import inspection from './inspection/inspection'
import inspection_call from './inspection/inspection_call'
import inspection_result from './inspection/inspection_result'
import inspection_item from './inspection/inspection_item'
import inspection_temp from './inspection/inspection_temp'
import inspection_plan from './inspection/inspection_plan'
import operate_item from './item/operate_item'

const store = createStore({
  actions: {
    clearAllStores({ dispatch }) {
      dispatch('auth/clearAll', null, { root: true })
      dispatch('alert/clearAll', null, { root: true })
      dispatch('loading/clearAll', null, { root: true })
      dispatch('master/clearAll', null, { root: true })
      dispatch('user/clearAll', null, { root: true })
      dispatch('item/clearAll', null, { root: true })
      dispatch('item_classification/clearAll', null, { root: true })
      dispatch('item_price/clearAll', null, { root: true })
      dispatch('client/clearAll', null, { root: true })
      dispatch('supplier/clearAll', null, { root: true })
      dispatch('export_data/clearAll', null, { root: true })
      dispatch('inspection/clearAll', null, { root: true })
      dispatch('inspection_call/clearAll', null, { root: true })
      dispatch('inspection_result/clearAll', null, { root: true })
      dispatch('inspection_item/clearAll', null, { root: true })
      dispatch('inspection_temp/clearAll', null, { root: true })
      dispatch('inspection_plan/clearAll', null, { root: true })
      dispatch('operate_item/clearAll', null, { root: true })
    },
    clearDataStores({ dispatch }) {
      dispatch('master/clearAll', null, { root: true })
      dispatch('user/clearAll', null, { root: true })
      dispatch('item/clearAll', null, { root: true })
      dispatch('item_classification/clearAll', null, { root: true })
      dispatch('item_price/clearAll', null, { root: true })
      dispatch('client/clearAll', null, { root: true })
      dispatch('supplier/clearAll', null, { root: true })
      dispatch('export_data/clearAll', null, { root: true })
      dispatch('inspection/clearAll', null, { root: true })
      dispatch('inspection_call/clearAll', null, { root: true })
      dispatch('inspection_result/clearAll', null, { root: true })
      dispatch('inspection_item/clearAll', null, { root: true })
      dispatch('inspection_temp/clearAll', null, { root: true })
      dispatch('inspection_plan/clearAll', null, { root: true })
      dispatch('operate_item/clearAll', null, { root: true })
    },
  },
  modules: {
    auth,
    alert,
    loading,
    master,
    user,
    item,
    item_classification,
    item_price,
    client,
    supplier,
    export_data,
    inspection,
    inspection_call,
    inspection_result,
    inspection_item,
    inspection_temp,
    inspection_plan,
    operate_item,
  },
})

export default store
