<template>
  <Navbar>
    <template v-slot:navbarTitle>単価管理</template>
  </Navbar>

  <main id="main" class="container-fluid pt-3">
    <div class="d-flex gap-2 mb-3">
      <h5 class="m-0 p-0"><i class="bi bi-cash me-1"></i>単価一覧</h5>
      <div class="ms-auto">
        <router-link
          :to="{
            name: 'ItemPriceImport',
          }"
          class="btn btn-dark me-2"
          ><span class="d-block d-sm-none"
            ><i class="bi bi-cloud-upload"></i
          ></span>
          <span class="d-none d-sm-block"
            ><i class="bi bi-cloud-upload me-1"></i>CSVから一括で登録する</span
          ></router-link
        >
        <button
          type="button"
          v-on:click.prevent="showItemPriceForm()"
          class="btn btn-primary"
        >
          <span class="d-block d-sm-none"><i class="bi bi-plus-lg"></i></span>
          <span class="d-none d-sm-block"
            ><i class="bi bi-plus-lg me-1"></i>単価を登録する</span
          >
        </button>
      </div>
    </div>

    <div class="d-flex justify-content-start gap-2 mb-2">
      <search-form
        :query="query"
        query-key="item_code"
        placeholder="品目コードで検索"
        @update-search-query="onUpdateSearchQuery"
        @open-search-modal="onOpenSearchModal"
      ></search-form>
      <Pagination :prev="prev" :next="next" @move-page="movePage" />
    </div>

    <!-- prices -->
    <div v-if="prices && prices.length > 0">
      <data-table :headers="dataHeaders" :items="prices" />
    </div>
    <p v-else class="mt-3">該当するデータはありません。</p>

    <!-- Modal -->
    <search-modal
      :query="query"
      :items="searchItems"
      @update-search-query="onUpdateSearchQuery"
      ref="searchModal"
    ></search-modal>

    <item-price-form ref="itemPriceForm" @after-submit="afterSubmit">
      <template v-slot:modalTitle>単価登録</template>
    </item-price-form>
  </main>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import Navbar from '@/components/Navbar.vue'
import Pagination from '@/components/AppNoCountPagination.vue'
import DataTable from '@/components/common/DataTable.vue'
import SearchForm from '@/components/common/SearchForm.vue'
import SearchModal from '@/components/common/SearchModal.vue'
import ItemPriceForm from '@/components/manage/ItemPriceForm.vue'

export default {
  name: 'ItemPriceList',
  components: {
    Navbar,
    Pagination,
    DataTable,
    SearchForm,
    SearchModal,
    ItemPriceForm,
  },

  data() {
    return {
      query: {},
      dataHeaders: [
        {
          key: 'item_code',
          label: '品目コード',
          class: 'col-2',
          format: 'code',
        },
        {
          key: 'item_name',
          label: '品目名',
          class: 'col-3',
          format: 'string',
        },
        {
          key: 'client_code',
          label: '顧客コード',
          class: 'col-1',
          format: 'code',
        },
        {
          key: 'client_name',
          label: '顧客名',
          class: 'col-3',
          format: 'string',
        },
        {
          key: 'unit_price',
          label: '単価',
          class: 'col-1',
          format: 'number',
        },
        {
          key: 'effective_date',
          label: '有効日',
          class: 'col-1',
          format: 'date',
        },
        {
          key: 'created_at',
          label: '登録日時',
          class: 'col-1',
          format: 'datetime',
        },
      ],
    }
  },
  computed: {
    ...mapGetters({
      prices: 'item_price/results',
      prev: 'item_price/prev',
      next: 'item_price/next',
    }),
    searchItems() {
      return [
        {
          id: 'item_code',
          label: '品目コード（完全一致）',
          type: 'text',
        },
        {
          id: 'item_name',
          label: '品目名（部分一致）',
          type: 'text',
        },
        {
          id: 'client_code',
          label: '顧客コード（完全一致）',
          type: 'text',
        },
        {
          id: 'client_name',
          label: '顧客名（部分一致）',
          type: 'text',
        },
      ]
    },
  },

  async created() {
    await this.getData()
  },

  methods: {
    ...mapActions({
      getData: 'item_price/fetchAll',
    }),
    movePage(page) {
      this.getData(Object.assign(this.query, { page: page }))
    },
    getQueryResult() {
      this.getData(this.query)
    },
    onOpenSearchModal() {
      this.$refs.searchModal.show()
    },
    onUpdateSearchQuery(query = {}) {
      this.query = query
      this.getQueryResult()
    },
    showItemPriceForm() {
      this.$refs.itemPriceForm.show()
    },
    afterSubmit() {
      this.getQueryResult()
    },
  },
}
</script>
