<template>
  <nav class="navbar navbar-expand navbar-light border-bottom">
    <div class="container">
      <span class="navbar-brand"
        ><slot name="navbarTitle">{{ title }}</slot>
      </span>
      <span class="navbar-text d-none d-sm-block me-auto"
        ><slot name="navbarSubTitle"
          ><i class="bi bi-person-square me-1"></i>{{ display_name }}</slot
        ></span
      >
      <ul v-if="buttons && buttons.length > 0" class="navbar-nav ms-auto">
        <li v-for="(button, index) in buttons" :key="index" class="nav-item">
          <a class="nav-link" href="#">{{ button.label }}</a>
        </li>
      </ul>
    </div>
  </nav>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'OperateNavbar',
  components: {},

  props: {
    buttons: {
      type: Array,
      required: false,
    },
  },

  data() {
    return {
      title: process.env.VUE_APP_TITLE,
    }
  },
  computed: {
    ...mapGetters({
      userData: 'auth/userData',
    }),
    display_name: function () {
      return this.userData('display_name')
    },
  },
}
</script>

<style scoped>
.nav-item {
  margin-left: 0.5rem;
}
</style>
