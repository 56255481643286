<template>
  <div class="ratio ratio-16x9 bg-secondary">
    <img
      @click="showLightBox(file)"
      :src="displayImage(file)"
      class="img-contain"
    />
  </div>
  <figcaption
    v-if="show_caption && file && file.caption"
    class="figure-caption mt-1 text-center"
  >
    {{ file.caption }}
  </figcaption>

  <vue-easy-lightbox
    escDisabled
    :visible="visible"
    :imgs="imgs"
    teleport="body"
    @hide="hideLightBox"
  ></vue-easy-lightbox>
</template>

<script>
import VueEasyLightbox from 'vue-easy-lightbox'
import { isVideo } from '@/utils/fileTypeUtils.js'

export default {
  name: 'AppImageViewer',
  components: {
    VueEasyLightbox,
  },

  props: {
    file: {
      type: [Object, null],
      required: true,
    },
    show_caption: {
      type: Boolean,
      require: false,
      default: true,
    },
    show_lightbox: {
      type: Boolean,
      require: false,
      default: true,
    },
  },

  data() {
    return {
      imgs: [],
      visible: false,
    }
  },

  methods: {
    isVideo,
    displayImage(file) {
      if (file?.file_path && file?.file_type) {
        return file.file_path
      }
      return require('@/assets/no_image.svg')
    },
    showLightBox(file) {
      if (!this.show_lightbox || !file || !file.file_path || !file.file_type) {
        return
      }

      if (this.isVideo(file)) {
        if (file?.file_status != 'publish') {
          return
        }
      }

      this.imgs = [
        {
          title: file.caption,
          src: file.file_path,
        },
      ]
      this.visible = true
    },
    hideLightBox() {
      this.visible = false
    },
  },
}
</script>

<style scoped>
.img-contain {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: contain;
}
</style>
