import api from '@/api'

// State（状態）
// https://next.vuex.vuejs.org/ja/guide/state.html
// 状態を監視するデータ
const state = () => ({
  data: {},
  list: [],
})

// Getters（算出プロパティ）
// https://next.vuex.vuejs.org/ja/guide/getters.html
// コンポーネントから状態を取得するプロパティまたは関数
const getters = {
  results: (state) => state.data.results,
  count: (state) => state.data.count,
  prev: (state) => state.data.previous,
  next: (state) => state.data.next,
  current_page: (state) => state.data.current_page,
  total_pages: (state) => state.data.total_pages,
  list: (state) => state.list,
}

// Mutations（状態変更）
// https://next.vuex.vuejs.org/ja/guide/mutations.html
// 状態を変更するメソッド
const mutations = {
  setData: (state, data) => (state.data = data),
  clearData: (state) => (state.data = []),
  setList: (state, list) => (state.list = list),
  clearList: (state) => (state.list = []),
}

// Actions（処理）
// https://next.vuex.vuejs.org/ja/guide/actions.html
// コンポーネントからミューテーションを実行する関数
const actions = {
  clearAll({ commit }) {
    return new Promise((resolve) => {
      commit('clearData')
      commit('clearList')
      resolve()
    })
  },
  async fetchAll({ commit }, queryParams) {
    const response = await api.get('manage/item-classifications/', {
      params: queryParams,
    })
    commit('setData', response.data)
  },
  async fetchList({ commit }, query_params) {
    const response = await api.get('manage/item-classifications/list/', {
      params: query_params,
    })
    commit('setList', response.data)
  },
  /**
   * データを追加する
   *
   * @param {object}
   * @return {object}
   */
  async postData({ state }, formData) {
    try {
      const response = await api.post('manage/item-classifications/', formData)
      state.data.results.push(response.data)
    } catch (error) {
      throw error
    }
  },
  /**
   * データを更新する
   *
   * @param {object}
   * @return {object}
   */
  async patchData({ state }, formData) {
    try {
      const response = await api.patch(
        'manage/item-classifications/' + formData.id + '/',
        formData
      )
      const index = state.data.results.findIndex(
        (result) => result.id === formData.id
      )
      if (index !== -1) {
        state.data.results[index] = response.data
      }
    } catch (error) {
      console.log(error)
      throw error
    }
  },
  /**
   * データを作成または更新する
   *
   * @param {object}
   * @return {object}
   */
  async postOrPatch({ dispatch }, formData) {
    // 必要なデータのみにする
    const requiredKeys = ['id', 'name']
    const submitData = Object.fromEntries(
      Object.entries(formData).filter(([key, value]) =>
        requiredKeys.includes(key)
      )
    )

    try {
      if ('id' in submitData && submitData['id']) {
        await dispatch('patchData', submitData)
      } else {
        await dispatch('postData', submitData)
      }
    } catch (error) {
      throw error
    }
  },
  /**
   * データを削除する
   *
   * @param {number}
   * @return {void}
   */
  async deleteData({ state }, id) {
    try {
      await api.delete('manage/item-classifications/' + id + '/')
      const index = state.data.results.findIndex((result) => result.id === id)
      state.data.results.splice(index, 1)
    } catch (error) {
      throw error
    }
  },
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}
